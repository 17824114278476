import React from "react";
import "./Homepage.css";
import { Countdown } from "@otterdam/otterdam-components";

function Homepage() {
  return (
    <div className="App">
      <header className="App-header">Otterdance 5</header>
      <Countdown targetDate="2025-01-01T00:00:00">
        <div>
          <h2>The countdown is over!</h2>
        </div>
      </Countdown>
    </div>
  );
}

export default Homepage;
